import * as z from "zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { createMagicLink, CreateMagicLinkData } from "../actions/create-magic-link";
import { MagicLink, MagicLinkTypes } from "../domain/magic-link";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import usersService from "../../services/users.service";
import { Data, MagicLinkModal } from "./magic-link-modal";

interface CreateMagicLinkProps {
    onClose: () => void;
}

export const CreateClientMagicLinkModal = ({ onClose }: CreateMagicLinkProps) => {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-token";
    const [magicLink, setMagicLink] = useState<MagicLink | undefined>();
    const [hasCopied, setHasCopied] = useState(false);

    useEffect(() => {
        if (hasCopied) {
            setTimeout(() => setHasCopied(false), 2000);
        }
    }, [hasCopied]);

    const magicLinkMutation = useMutation({
        mutationFn: async ({ authToken, data }: { authToken: string; data: CreateMagicLinkData }) => {
            return await createMagicLink({ authToken, data });
        },
    });

    const handleSubmit = async (data: Data) => {
        const magicLink = await magicLinkMutation.mutateAsync({
            authToken,
            data: {
                type: MagicLinkTypes.clientSignUp,
                data,
                createdById: loggedInUser.id || "no-userId",
                options: {
                    affiliateUserId: data.affiliateId || undefined,
                    docCollectorId: data.docCollectorId || undefined,
                },
            },
        });
        setMagicLink(magicLink);
    };

    const magicLinkLink = magicLink ? `${window.location.origin}/magic/${magicLink.code}` : undefined;

    const affiliatesQuery = useQuery(["users", "affiliates", "magicLink"], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });
    const affiliates = useMemo(() => affiliatesQuery.data || [], [affiliatesQuery.data]);

    const usersQuery = useQuery(["users", "magicLink"], async () => {
        const response = await usersService.getAll();
        return response?.data || [];
    });
    const users = useMemo(() => usersQuery.data || [], [usersQuery.data]);

    return (
        <MagicLinkModal
            onClose={onClose}
            setHasCopied={setHasCopied}
            hasCopied={hasCopied}
            magicLinkLink={magicLinkLink}
            handleSubmit={handleSubmit}
            users={users}
            affiliates={affiliates}
            mutation={magicLinkMutation}
            magicLink={magicLink as MagicLink}
            title={magicLink ? "" : "New Client Magic Link"}
            okText="Create Magic Link"
            type="create"
        />
    );
};
