import { z } from "zod";
import { userSchema } from "../../jason-proof-of-concept/users/domain/user";

export enum MagicLinkTypes {
    clientSignUp = "clientSignUp",
    affiliateSignUp = "affiliateSignUp",
    userCompanyInvite = "userCompanyInvite",
}

export const magicLinkSchema = z.object({
    id: z.string().uuid(),
    createdById: z.string().uuid(),
    createdByUser: userSchema.optional(),
    type: z.nativeEnum(MagicLinkTypes),
    data: z.record(z.any()),
    code: z.string(),
    usedAt: z.coerce.date().nullish(),
    options: z
        .object({
            affiliateUserId: z.string().uuid().optional(),
            docCollectorId: z.string().uuid().optional(),
            companyId: z.number().optional(),
        })
        .nullish(),
});

export const magicLinkResponseSchema = magicLinkSchema.extend({
    createdByUser: z
        .object({
            id: z.string().uuid(),
            firstName: z.string(),
            lastName: z.string(),
            companyName: z.string(),
            email: z.string().email(),
            role: z.string(),
            createdAt: z.coerce.date(),
            updatedAt: z.coerce.date(),
        })
        .nullish(),
});

export type MagicLink = z.infer<typeof magicLinkSchema>;
export type MagicLinkResponse = z.infer<typeof magicLinkResponseSchema>;
